import React from 'react'
import LayoutMain from '../layout/LayoutMain'

const FourOFour = () => {
	return (
		<LayoutMain>
			<div className="container--standard">
				<h1>Sorry ... But the page your were looking could not be found.</h1>
			</div>
		</LayoutMain>
	)
}

export default FourOFour
